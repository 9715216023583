exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-practices-cloud-native-we-automate-everything-coding-standards-are-checked-mdx": () => import("./../../../src/pages/practices/cloud-native/we-automate-everything/coding-standards-are-checked.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-automate-everything-coding-standards-are-checked-mdx" */),
  "component---src-pages-practices-cloud-native-we-automate-everything-simulated-game-days-mdx": () => import("./../../../src/pages/practices/cloud-native/we-automate-everything/simulated-game-days.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-automate-everything-simulated-game-days-mdx" */),
  "component---src-pages-practices-cloud-native-we-automate-everything-test-optimisation-mdx": () => import("./../../../src/pages/practices/cloud-native/we-automate-everything/test-optimisation.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-automate-everything-test-optimisation-mdx" */),
  "component---src-pages-practices-cloud-native-we-continuously-deploy-coding-standards-are-checked-mdx": () => import("./../../../src/pages/practices/cloud-native/we-continuously-deploy/coding-standards-are-checked.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-continuously-deploy-coding-standards-are-checked-mdx" */),
  "component---src-pages-practices-cloud-native-we-continuously-deploy-pipelined-deployments-mdx": () => import("./../../../src/pages/practices/cloud-native/we-continuously-deploy/pipelined-deployments.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-continuously-deploy-pipelined-deployments-mdx" */),
  "component---src-pages-practices-cloud-native-we-continuously-deploy-stop-the-line-mdx": () => import("./../../../src/pages/practices/cloud-native/we-continuously-deploy/stop-the-line.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-continuously-deploy-stop-the-line-mdx" */),
  "component---src-pages-practices-cloud-native-we-continuously-deploy-test-driven-development-mdx": () => import("./../../../src/pages/practices/cloud-native/we-continuously-deploy/test-driven-development.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-continuously-deploy-test-driven-development-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-alerts-and-notifications-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/alerts-and-notifications.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-alerts-and-notifications-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-blue-green-deployments-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/blue-green-deployments.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-blue-green-deployments-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-chaos-engineering-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/chaos-engineering.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-chaos-engineering-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-distributed-tracing-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/distributed-tracing.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-distributed-tracing-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-logging-and-centralized-management-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/logging-and-centralized-management.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-logging-and-centralized-management-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-slas-and-slos-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/slas-and-slos.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-slas-and-slos-mdx" */),
  "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-the-three-pillars-mdx": () => import("./../../../src/pages/practices/cloud-native/we-do-day-2-operations-on-day-1/the-three-pillars.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-do-day-2-operations-on-day-1-the-three-pillars-mdx" */),
  "component---src-pages-practices-cloud-native-we-start-with-production-first-hello-world-pipeline-mdx": () => import("./../../../src/pages/practices/cloud-native/we-start-with-production-first/hello-world-pipeline.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-start-with-production-first-hello-world-pipeline-mdx" */),
  "component---src-pages-practices-cloud-native-we-start-with-production-first-walking-skeleton-mdx": () => import("./../../../src/pages/practices/cloud-native/we-start-with-production-first/walking-skeleton.mdx" /* webpackChunkName: "component---src-pages-practices-cloud-native-we-start-with-production-first-walking-skeleton-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-dead-code-elimination-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/dead-code-elimination.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-dead-code-elimination-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-example-mapping-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/example-mapping.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-example-mapping-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-fail-fast-gates-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/fail-fast-gates.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-fail-fast-gates-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-pair-programming-practical-examples-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/pair-programming-practical-examples.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-pair-programming-practical-examples-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-regression-testing-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/regression-testing.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-regression-testing-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-shift-left-testing-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/shift-left-testing.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-shift-left-testing-mdx" */),
  "component---src-pages-practices-code-as-craft-automated-tests-what-is-a-good-test-mdx": () => import("./../../../src/pages/practices/code-as-craft/automated-tests/what-is-a-good-test.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-automated-tests-what-is-a-good-test-mdx" */),
  "component---src-pages-practices-code-as-craft-coding-practice-the-boy-scout-rule-mdx": () => import("./../../../src/pages/practices/code-as-craft/coding-practice/the-boy-scout-rule.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-coding-practice-the-boy-scout-rule-mdx" */),
  "component---src-pages-practices-code-as-craft-coding-practice-trunk-based-development-practice-mdx": () => import("./../../../src/pages/practices/code-as-craft/coding-practice/trunk-based-development-practice.mdx" /* webpackChunkName: "component---src-pages-practices-code-as-craft-coding-practice-trunk-based-development-practice-mdx" */),
  "component---src-pages-practices-engagement-we-deliver-avoiding-dependencies-mdx": () => import("./../../../src/pages/practices/engagement/we-deliver/avoiding-dependencies.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-deliver-avoiding-dependencies-mdx" */),
  "component---src-pages-practices-engagement-we-deliver-security-first-approach-mdx": () => import("./../../../src/pages/practices/engagement/we-deliver/security-first-approach.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-deliver-security-first-approach-mdx" */),
  "component---src-pages-practices-engagement-we-deliver-teaching-with-4-c-mdx": () => import("./../../../src/pages/practices/engagement/we-deliver/teaching-with-4c.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-deliver-teaching-with-4-c-mdx" */),
  "component---src-pages-practices-engagement-we-demonstrate-success-decentralizing-decision-making-mdx": () => import("./../../../src/pages/practices/engagement/we-demonstrate-success/decentralizing-decision-making.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-demonstrate-success-decentralizing-decision-making-mdx" */),
  "component---src-pages-practices-engagement-we-demonstrate-success-lightweight-reporting-mdx": () => import("./../../../src/pages/practices/engagement/we-demonstrate-success/lightweight-reporting.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-demonstrate-success-lightweight-reporting-mdx" */),
  "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-building-ownership-retro-actions-mdx": () => import("./../../../src/pages/practices/engagement/we-enhance-team-enthusiasm/building-ownership-retro-actions.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-building-ownership-retro-actions-mdx" */),
  "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-keep-it-casual-mdx": () => import("./../../../src/pages/practices/engagement/we-enhance-team-enthusiasm/keep-it-casual.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-keep-it-casual-mdx" */),
  "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-rotation-in-action-mdx": () => import("./../../../src/pages/practices/engagement/we-enhance-team-enthusiasm/rotation-in-action.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-rotation-in-action-mdx" */),
  "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-see-one-do-one-teach-one-mdx": () => import("./../../../src/pages/practices/engagement/we-enhance-team-enthusiasm/see-one-do-one-teach-one.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-see-one-do-one-teach-one-mdx" */),
  "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-swot-mdx": () => import("./../../../src/pages/practices/engagement/we-enhance-team-enthusiasm/swot.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-enhance-team-enthusiasm-swot-mdx" */),
  "component---src-pages-practices-engagement-we-radiate-our-success-making-work-visible-mdx": () => import("./../../../src/pages/practices/engagement/we-radiate-our-success/making-work-visible.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-radiate-our-success-making-work-visible-mdx" */),
  "component---src-pages-practices-engagement-we-radiate-our-success-nps-mdx": () => import("./../../../src/pages/practices/engagement/we-radiate-our-success/nps.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-radiate-our-success-nps-mdx" */),
  "component---src-pages-practices-engagement-we-radiate-our-success-overview-dashboard-mdx": () => import("./../../../src/pages/practices/engagement/we-radiate-our-success/overview-dashboard.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-radiate-our-success-overview-dashboard-mdx" */),
  "component---src-pages-practices-engagement-we-radiate-our-success-weekly-notes-mdx": () => import("./../../../src/pages/practices/engagement/we-radiate-our-success/weekly-notes.mdx" /* webpackChunkName: "component---src-pages-practices-engagement-we-radiate-our-success-weekly-notes-mdx" */),
  "component---src-pages-practices-team-first-teams-can-be-successful-cognative-load-workshop-mdx": () => import("./../../../src/pages/practices/team-first/teams-can-be-successful/cognative-load-workshop.mdx" /* webpackChunkName: "component---src-pages-practices-team-first-teams-can-be-successful-cognative-load-workshop-mdx" */),
  "component---src-pages-practices-team-first-teams-can-be-successful-team-api-mdx": () => import("./../../../src/pages/practices/team-first/teams-can-be-successful/team-api.mdx" /* webpackChunkName: "component---src-pages-practices-team-first-teams-can-be-successful-team-api-mdx" */),
  "component---src-pages-practices-team-first-teams-can-be-successful-team-charter-mdx": () => import("./../../../src/pages/practices/team-first/teams-can-be-successful/team-charter.mdx" /* webpackChunkName: "component---src-pages-practices-team-first-teams-can-be-successful-team-charter-mdx" */),
  "component---src-pages-practices-team-first-teams-can-be-successful-team-overview-mdx": () => import("./../../../src/pages/practices/team-first/teams-can-be-successful/team-overview.mdx" /* webpackChunkName: "component---src-pages-practices-team-first-teams-can-be-successful-team-overview-mdx" */),
  "component---src-pages-practices-team-first-teams-can-be-successful-team-topologies-mdx": () => import("./../../../src/pages/practices/team-first/teams-can-be-successful/team-topologies.mdx" /* webpackChunkName: "component---src-pages-practices-team-first-teams-can-be-successful-team-topologies-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-five-bold-steps-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-have-a-clear-vision/five-bold-steps.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-five-bold-steps-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-inputs-and-outcomes-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-have-a-clear-vision/inputs-and-outcomes.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-inputs-and-outcomes-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-north-star-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-have-a-clear-vision/north-star.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-north-star-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-vision-statement-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-have-a-clear-vision/vision-statement.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-have-a-clear-vision-vision-statement-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-domain-mapping-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-understand-the-customer/domain-mapping.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-domain-mapping-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-empathy-mapping-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-understand-the-customer/empathy-mapping.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-empathy-mapping-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-lean-startup-principles-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-understand-the-customer/lean-startup-principles.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-lean-startup-principles-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-stakeholder-mapping-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-understand-the-customer/stakeholder-mapping.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-stakeholder-mapping-mdx" */),
  "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-value-stream-mapping-mdx": () => import("./../../../src/pages/practices/vision-and-empathy/we-understand-the-customer/value-stream-mapping.mdx" /* webpackChunkName: "component---src-pages-practices-vision-and-empathy-we-understand-the-customer-value-stream-mapping-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-measure-everything-experiment-retrospective-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-measure-everything/experiment-retrospective.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-measure-everything-experiment-retrospective-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-measure-everything-smart-experiment-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-measure-everything/smart-experiment.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-measure-everything-smart-experiment-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-measure-everything-think-big-act-small-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-measure-everything/think-big-act-small.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-measure-everything-think-big-act-small-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-measure-everything-use-experiments-to-introduce-change-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-measure-everything/use-experiments-to-introduce-change.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-measure-everything-use-experiments-to-introduce-change-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-acceptance-criteria-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/acceptance-criteria.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-acceptance-criteria-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-dot-voting-on-agreements-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/dot-voting-on-agreements.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-dot-voting-on-agreements-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-invest-criteria-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/invest-criteria.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-invest-criteria-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-rightsizing-user-stories-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/rightsizing-user-stories.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-rightsizing-user-stories-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-story-acceptance-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/story-acceptance.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-story-acceptance-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-the-five-whys-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/the-five-whys.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-the-five-whys-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-value-driven-stories-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-plan-work-as-user-stories/value-driven-stories.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-plan-work-as-user-stories-value-driven-stories-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-actor-assessment-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-set-goals-every-quarter/actor-assessment.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-actor-assessment-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-goals-and-non-goals-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-set-goals-every-quarter/goals-and-non-goals.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-goals-and-non-goals-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-risks-and-mitigations-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-set-goals-every-quarter/risks-and-mitigations.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-set-goals-every-quarter-risks-and-mitigations-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-backlog-and-icebox-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/backlog-and-icebox.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-backlog-and-icebox-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-daily-standup-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/daily-standup.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-daily-standup-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-pomodoro-pairing-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/pomodoro-pairing.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-pomodoro-pairing-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-sailboat-retrospectives-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/sailboat-retrospectives.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-sailboat-retrospectives-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-three-amigos-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/three-amigos.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-three-amigos-mdx" */),
  "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-wip-limit-mdx": () => import("./../../../src/pages/practices/xp-and-agility/we-work-in-weekly-iterations/wip-limit.mdx" /* webpackChunkName: "component---src-pages-practices-xp-and-agility-we-work-in-weekly-iterations-wip-limit-mdx" */)
}

